.Message__wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 20vh;
  text-align: center;
}
.Message {
  font-size: 1.4rem;
  font-family: 'Roboto Mono', monospace;
  font-weight: normal;
  line-height: normal;
  color: #515151;
  margin-bottom: 2.875rem;
}
.Message__cta {
  font-size: 0.75rem;
  font-family: 'Roboto Mono', monospace;
  font-weight: normal;
  line-height: normal;
  color: #515151;
  color: #4A47E9;
}
