.HNHeader {
  background: #4A47E9;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0 1.563rem;
  height: 3.625em;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.HNHeader__logo {
  display: inline-block;
  width: 5.938rem;
}
.HNHeader__logo-text {
  display: inline;
}
.HNHeader__logo-text,
.HNHeader__nav-link {
  font-size: 0.75rem;
  font-family: 'Roboto Mono', monospace;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  color: #FFFFFF;
}
.HNHeader__logo-text {
  border: 1px solid white;
  padding: 0.333em;
}
.HNHeader__nav {
  -webkit-flex-grow: 2;
  flex-grow: 2;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.HNHeader__nav-icon {
  margin-right: 0.5em;
}
.HNHeader__nav-link {
  padding: 0 3rem;
  min-width: 12rem;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.HNHeader__nav-link:not(:last-child) {
  margin-right: 20px;
}
.HNHeader__nav-link_selected {
  border-bottom: 0.333em solid #ed6535;
}
@media screen and (max-width: 920px) {
  .HNHeader__nav-link {
    min-width: none;
  }
}
@media screen and (max-width: 720px) {
  .HNHeader {
    display: block;
    height: auto;
    padding: 1.5rem 1.563rem;
  }
  .HNHeader__logo {
    display: block;
    width: 5.938rem;
    text-align: center;
    margin: auto;
    margin-bottom: 0.9375rem;
  }
  .HNHeader__nav {
    display: block;
    max-width: 14.0625rem;
    margin: auto;
  }
  .HNHeader__nav-link {
    padding: 0.625rem 3rem;
    min-width: none;
  }
  .HNHeader__nav-link:not(:last-child) {
    margin-right: 0;
    margin-bottom: 1.25rem;
  }
  .HNHeader__nav-link_selected {
    border: 0.333em solid #ed6535;
  }
}
