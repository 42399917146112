/*
==============================================
Fonts Installation
==============================================
*/
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v14/S6uyw4BMUTPHjx4wWw.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459Wlhzg.ttf) format('truetype');
}
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v42/flUhRq6tzZclQEJ-Vdg-IuiaDsNZ.ttf) format('truetype');
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
}
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto Mono'), local('RobotoMono-Regular'), url(https://fonts.gstatic.com/s/robotomono/v5/L0x5DF4xlVMF-BfR8bXMIjhLq3o.ttf) format('truetype');
}
/*
==============================================
Neutralize styles
==============================================
*/
html {
  /* base font size */
  font-size: 16px;
}
body {
  box-sizing: border-box;
  margin: 0;
}
ul {
  padding: 0;
  margin: 0;
}
ul li {
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
p,
hr {
  padding: 0;
  margin: 0;
}
a {
  cursor: pointer;
  text-decoration: none;
  color: white;
}
button,
input,
optgroup,
select,
textarea {
  font-family: 'Lato', sans-serif;
}
button {
  background-color: transparent;
  border: none;
}
input,
button,
form,
a {
  padding: 0;
  background: none;
  border: none;
}
input:focus,
button:focus,
form:focus,
a:focus {
  outline: none;
}
* {
  box-sizing: border-box;
}
