.Story {
  cursor: move;
  width: 12rem;
  height: 13.5rem;
  padding: 0.75rem 1.5rem;
  background: #F6F6F6;
  position: relative;
}
.Story_bkmarked {
  -webkit-box-shadow: 0.333em 0.333em 0 0 #ed6635;
  -moz-box-shadow: 0.333em 0.333em 0 0 #ed6635;
  box-shadow: 0.333em 0.333em 0 0 #ed6635;
}
.Story__haeding-link,
.Story__haeding-text,
.Story__score,
.Story__author {
  font-size: 0.75rem;
  font-family: 'Roboto Mono', monospace;
  font-weight: normal;
  line-height: normal;
  color: #000000;
}
.Story__heading {
  margin-bottom: 1.167em;
}
.Story__detail {
  color: #4A47E9;
}
.Story__button {
  position: absolute;
  bottom: 0.75rem;
  cursor: pointer;
}
.Story__heart {
  width: 1.167rem;
}
