/*
==============================================
Fonts Installation
==============================================
*/
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v14/S6uyw4BMUTPHjx4wWw.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459Wlhzg.ttf) format('truetype');
}
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v42/flUhRq6tzZclQEJ-Vdg-IuiaDsNZ.ttf) format('truetype');
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
}
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto Mono'), local('RobotoMono-Regular'), url(https://fonts.gstatic.com/s/robotomono/v5/L0x5DF4xlVMF-BfR8bXMIjhLq3o.ttf) format('truetype');
}
/*
==============================================
Neutralize styles
==============================================
*/
html {
  /* base font size */
  font-size: 16px;
}
body {
  box-sizing: border-box;
  margin: 0;
}
ul {
  padding: 0;
  margin: 0;
}
ul li {
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
p,
hr {
  padding: 0;
  margin: 0;
}
a {
  cursor: pointer;
  text-decoration: none;
  color: white;
}
button,
input,
optgroup,
select,
textarea {
  font-family: 'Lato', sans-serif;
}
button {
  background-color: transparent;
  border: none;
}
input,
button,
form,
a {
  padding: 0;
  background: none;
  border: none;
}
input:focus,
button:focus,
form:focus,
a:focus {
  outline: none;
}
* {
  box-sizing: border-box;
}

.HNHeader {
  background: #4A47E9;
  display: flex;
  padding: 0 1.563rem;
  height: 3.625em;
  align-items: center;
}
.HNHeader__logo {
  display: inline-block;
  width: 5.938rem;
}
.HNHeader__logo-text {
  display: inline;
}
.HNHeader__logo-text,
.HNHeader__nav-link {
  font-size: 0.75rem;
  font-family: 'Roboto Mono', monospace;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  color: #FFFFFF;
}
.HNHeader__logo-text {
  border: 1px solid white;
  padding: 0.333em;
}
.HNHeader__nav {
  flex-grow: 2;
  height: 100%;
  display: flex;
  align-items: center;
}
.HNHeader__nav-icon {
  margin-right: 0.5em;
}
.HNHeader__nav-link {
  padding: 0 3rem;
  min-width: 12rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.HNHeader__nav-link:not(:last-child) {
  margin-right: 20px;
}
.HNHeader__nav-link_selected {
  border-bottom: 0.333em solid #ed6535;
}
@media screen and (max-width: 920px) {
  .HNHeader__nav-link {
    min-width: none;
  }
}
@media screen and (max-width: 720px) {
  .HNHeader {
    display: block;
    height: auto;
    padding: 1.5rem 1.563rem;
  }
  .HNHeader__logo {
    display: block;
    width: 5.938rem;
    text-align: center;
    margin: auto;
    margin-bottom: 0.9375rem;
  }
  .HNHeader__nav {
    display: block;
    max-width: 14.0625rem;
    margin: auto;
  }
  .HNHeader__nav-link {
    padding: 0.625rem 3rem;
    min-width: none;
  }
  .HNHeader__nav-link:not(:last-child) {
    margin-right: 0;
    margin-bottom: 1.25rem;
  }
  .HNHeader__nav-link_selected {
    border: 0.333em solid #ed6535;
  }
}

.Story {
  cursor: move;
  width: 12rem;
  height: 13.5rem;
  padding: 0.75rem 1.5rem;
  background: #F6F6F6;
  position: relative;
}
.Story_bkmarked {
  box-shadow: 0.333em 0.333em 0 0 #ed6635;
}
.Story__haeding-link,
.Story__haeding-text,
.Story__score,
.Story__author {
  font-size: 0.75rem;
  font-family: 'Roboto Mono', monospace;
  font-weight: normal;
  line-height: normal;
  color: #000000;
}
.Story__heading {
  margin-bottom: 1.167em;
}
.Story__detail {
  color: #4A47E9;
}
.Story__button {
  position: absolute;
  bottom: 0.75rem;
  cursor: pointer;
}
.Story__heart {
  width: 1.167rem;
}

.Stories {
  margin: 0 7.501rem;
}
@media screen and (max-width: 920px) {
  .Stories {
    width: 90%;
    margin: auto;
  }
}

.Loader__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  text-align: center;
}

.Message__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 20vh;
  text-align: center;
}
.Message {
  font-size: 1.4rem;
  font-family: 'Roboto Mono', monospace;
  font-weight: normal;
  line-height: normal;
  color: #515151;
  margin-bottom: 2.875rem;
}
.Message__cta {
  font-size: 0.75rem;
  font-family: 'Roboto Mono', monospace;
  font-weight: normal;
  line-height: normal;
  color: #515151;
  color: #4A47E9;
}

